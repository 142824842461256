import React from "react"
import content from "../content.json"
import AboutServices from "../components/AboutServices"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import TherapiesServices from "../components/TherapiesServices"

export default function Servicios() {
  return (
    <Layout>
      <Seo {...content.pages.servicios.seo} />
      <AboutServices />
      <TherapiesServices />
    </Layout>
  )
}
